import {HttpError, IResourceComponentsProps, useNavigation, useTranslate,} from "@pankod/refine-core";
import {
    CreateButton,
    getDefaultSortOrder,
    List,
    RefreshButton,
    ShowButton,
    Space,
    Table,
    TextField,
    useTable,
} from "@pankod/refine-antd";
import React, {useEffect, useState} from "react";
import {ITedee} from "interfaces";
import {getTedeeBatteryAndStateData, getTedeeKeypadBatteryData} from "./service/tedeeService";

export const TedeeList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const {show, list} = useNavigation();
    const [tedeeData, setTedeeData] = useState<Record<string, {
        batteryLevel: number,
        state: string,
        color: string
    }>>({});
    const [keypadData, setKeypadData] = useState<Record<string, { batteryLevel: number | string, name: string }>>({});


    const {tableProps, sorter, searchFormProps} = useTable<ITedee, HttpError>({
        initialSorter: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });

    const handleRefresh = () => {
        window.location.reload();
    };

    useEffect(() => {
        const getData = async () => {
            const batteryAndStateData = await getTedeeBatteryAndStateData(); // Locks
            const batteryKeypadData = await getTedeeKeypadBatteryData(); // Keypads
            setTedeeData(batteryAndStateData);
            setKeypadData(batteryKeypadData);
        };
        getData();
    }, []);


    return (
        <List
            pageHeaderProps={{
                title: t("menu.tedee"),
                extra: [
                    <RefreshButton key="refresh" onClick={handleRefresh}>{t("tedee.refresh")}</RefreshButton>,
                    <CreateButton> {t("tedee.createcode")}</CreateButton>,
                ],
            }}
        >
            <Table
                {...tableProps}
                pagination={{
                    ...tableProps.pagination,
                    showSizeChanger: true,
                }}
                rowKey="id"
            >
                <Table.Column
                    dataIndex="name"
                    key="name"
                    title={t("tedee.name")}
                    render={(value, record: ITedee) => <TextField value={value} onClick={() => {
                        show("tedee", record?.id);
                    }}/>}
                    defaultSortOrder={getDefaultSortOrder("name", sorter)}
                    sorter
                />
                <Table.Column
                    dataIndex="lock_battery"
                    key="lock_battery"
                    title={t("tedee.lock_battery")}
                    render={(_, record: ITedee) => (
                        <TextField
                            value={tedeeData[record.lock_id] !== undefined ? `${tedeeData[record.lock_id].batteryLevel}%` : 'N/A'}/>
                    )}
                />
                <Table.Column
                    dataIndex="keypad_battery"
                    key="keypad_battery"
                    title={t("tedee.keypad_battery")}
                    render={(_, record: ITedee) => {
                        const keypad = keypadData[record.lock_id];
                        return (
                            <TextField
                                value={keypad ? `${keypad.batteryLevel}%` : 'N/A'}
                            />
                        );
                    }}
                />
                <Table.Column
                    dataIndex="state"
                    key="state"
                    title={t("tedee.state")}
                    render={(_, record: ITedee) => (
                        <TextField
                            value={tedeeData[record.lock_id] !== undefined ? `${tedeeData[record.lock_id].state}` : 'N/A'}
                            style={{color: tedeeData[record.lock_id]?.color || 'black'}}/>
                    )}
                />
                <Table.Column
                    dataIndex="actions"
                    key="actions"
                    title={t("tedee.action")}
                    render={(_, record: ITedee) => (
                        <Space>
                            <ShowButton hideText size="small" recordItemId={record.id}/>
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
