import { message } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

const API_BASE_URL = 'https://api.tedee.com/api/v1.30';
const API_HEADERS = {
    'Authorization': 'PersonalKey jaeAEm.jKiJgQDNgB9QJAJaLSmBVpysfXKz+uEqqmH8CJbWEwg='
};

const STATE_INFO: Record<number, { name: string, color: string }> = {
    0: { name: "Non Calibrée", color: "grey" },
    1: { name: "Calibrée", color: "grey" },
    2: { name: "Ouverte", color: "green" },
	4: { name: "Ouverte", color: "green" },
    3: { name: "Verrouillée", color: "red" },
    5: { name: "Verrouillée", color: "red" },
    6: { name: "Verrouillée", color: "red" },
    7: { name: "Ouverte", color: "green" },
    8: { name: "Ouverte", color: "green" },
    9: { name: "Inconnu", color: "black" },
    18: { name: "Mise à jour en cours...", color: "pink" }
};

// Fonction générique pour faire une requête GET
const fetchData = async (endpoint: string) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'GET',
        headers: API_HEADERS,
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
};

const postData = async (endpoint: string) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: API_HEADERS,
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
};

// Fonction générique pour faire une requête PUT
const putData = async (endpoint: string, body: any) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'PUT',
        headers: {
            ...API_HEADERS,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
};

// Fonction générique pour faire une requête PATCH
const patchData = async (endpoint: string, body: any) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'PATCH',
        headers: {
            ...API_HEADERS,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
};

// Fonction générique pour faire une requête DELETE
const deleteData = async (endpoint: string) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'DELETE',
        headers: API_HEADERS,
    });
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message}`);
    }
    return await response.json();
};

// Fonction pour récupérer les données des serrures Tedee
export const fetchTedeeData = async () => {
    const data = await fetchData('/my/lock/sync');
    return data.result;
};

// Fonction pour transformer les données des serrures Tedee
export const getTedeeBatteryAndStateData = async () => {
    const data = await fetchTedeeData();
    const batteryAndStateData = data.reduce((acc: Record<string, { batteryLevel: number | string, state: string, color: string }>, item: any) => {
        const state = item.lockProperties?.state;
        const batteryLevel = item.lockProperties?.batteryLevel ?? 'N/A';
        const stateInfo = STATE_INFO[state] || { name: "Unknown", color: "black" };
        acc[item.id] = {
            batteryLevel: batteryLevel,
            state: stateInfo.name,
            color: stateInfo.color,
        };
        return acc;
    }, {});
    return batteryAndStateData;
};

// Fonction pour récupérer les données de batterie des keypads
export const getTedeeKeypadBatteryData = async () => {
    const data = await fetchData('/my/keypad');
    const batteryData = data.result.reduce((acc: Record<string, { batteryLevel: number | string, name: string }>, item: any) => {
        const lockId = item.connectedToLockId; // Lié au lock
        if (lockId) {
            acc[lockId] = {
                batteryLevel: item.deviceState?.batteryLevel ?? 'N/A',
                name: item.name ?? 'Unknown',
            };
        }
        return acc;
    }, {});
    return batteryData;
};



// Fonction pour récupérer les codes (pins) d'une porte spécifique
export const fetchTedeePins = async (lockId: string) => {
    const data = await fetchData(`/my/lock/${lockId}/pin`);
    const pins = data.result.pins;
    const guestPins = pins.filter((pin: any) => /^Guest :/.test(pin.alias));
    const staffPins = pins.filter((pin: any) => /^Staff :/.test(pin.alias));
    const otherPins = pins.filter((pin: any) => !/^Guest :/.test(pin.alias) && !/^Staff :/.test(pin.alias));
    return { guestPins, staffPins, otherPins };
};

// Nouvelle fonction pour récupérer les informations détaillées d'un pin
export const fetchTedeePinDetails = async (lockId: string, pinId: number) => {
    const data = await fetchData(`/my/lock/${lockId}/pin/${pinId}`);
    return data.result;
};

// Fonction pour récupérer les PINs existants d'une serrure spécifique
export const fetchExistingTedeePins = async (lockId: string) => {
    try {
        const response = await fetch(`${API_BASE_URL}/my/lock/${lockId}/pin`, {
            method: 'GET',
            headers: API_HEADERS,
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch pins for lock ${lockId}. Status: ${response.status}`);
        }

        const data = await response.json();
        return data.result.pins; // Retourne la liste des PINs
    } catch (error) {
        console.error("Error fetching existing pins:", error);
        throw error;
    }
};

// Fonction pour récupérer et gérer les détails des pins
export const handlePinDetails = async (
    lockId: string,
    pinId: number,
    setPinDetails: React.Dispatch<React.SetStateAction<Record<number, any>>>
) => {
    const details = await fetchTedeePinDetails(lockId, pinId);
    setPinDetails(prevDetails => ({ ...prevDetails, [pinId]: details }));
};

export const unlockLock = async (lockId: string) => {
	await fetchData(`/my/lock/${lockId}/sync`);
	await postData(`/my/lock/${lockId}/operation/unlock`);
	const response = await postData(`/my/lock/${lockId}/operation/pull`);
	if (response.statusCode === 202) {
		message.success("Lock unlocked successfully");
	} else {
		message.error("Failed to unlock lock");
	}
};

export const lockLock = async (lockId: string) => {
	await fetchData(`/my/lock/${lockId}/sync`);
	const response = await postData(`/my/lock/${lockId}/operation/lock`);
	if (response.statusCode === 202) {
		message.success("Lock locked successfully");
	} else {
		message.error("Failed to lock the door");
	}
};

// Fonction pour créer un PIN
export const createTedeePin = async (lockId: string, pinData: any) => {
    const response = await fetch(`${API_BASE_URL}/my/lock/${lockId}/pin`, {
        method: 'POST',
        headers: {
            ...API_HEADERS,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(pinData),
    });

    const responseData = await response.json();

    // Retourner la réponse entière pour capturer le code d'état
    if (!response.ok) {
        // Inclure le code d'état dans l'erreur levée
        const error = new Error(`HTTP error! Status: ${response.status}`);
        (error as any).statusCode = response.status;
        throw error;
    }

    return responseData;
};

// Fonction pour update un PIN
export const updateTedeePin = async (lockId: string, pinId: number, pinData: any) => {
    const response = await fetch(`${API_BASE_URL}/my/lock/${lockId}/pin/${pinId}`, {
        method: 'PUT',
        headers: {
            ...API_HEADERS,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(pinData),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Vérifie si la réponse a un contenu avant de l'analyser en JSON
    const responseText = await response.text();
    if (responseText) {
        return JSON.parse(responseText);  // Retourne le contenu JSON si disponible
    } else {
        return {};  // Si la réponse est vide, retourne un objet vide
    }
};


// Fonction pour supprimer un PIN
export const deleteTedeePin = async (lockId: string, pinId: number) => {
    try {
        const response = await fetch(`${API_BASE_URL}/my/lock/${lockId}/pin/${pinId}`, {
            method: 'DELETE',
            headers: API_HEADERS,
        });
        if (!response.ok) {
            let errorMessage = `HTTP error! status: ${response.status}`;
            try {
                const errorData = await response.json();
                errorMessage += `, message: ${errorData.message}`;
            } catch (jsonError) {
                // Si la réponse ne contient pas de JSON valide, ignore cette erreur.
            }
            throw new Error(errorMessage);
        }

        // Vérifier si la réponse est vide avant de l'analyser comme JSON
        let result;
        try {
            result = await response.json();
        } catch (jsonError) {
            // Si l'analyse JSON échoue, assumer que la réponse est vide
            result = {};
        }

        message.success("PIN deleted successfully");
        return result;
    } catch (error) {
        if (error instanceof Error) {
            message.error(`Failed to delete PIN: ${error.message}`);
        } else {
            message.error("Failed to delete PIN: An unknown error occurred.");
        }
        throw error;
    }
};

// Fonction pour envoyer la requête au serveur Flask pour mettre à jour la suite
export const updateSuite = async (suiteId: number) => {
    try {
        const response = await fetch('https://mihscript.mihotel.fr/update_suite', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                suite_id: suiteId,
                api_key: 'aa1d062a-5fc2-4996-99e0-bc82499e26d3', // Clé API sécurisée à gérer en back-end si possible
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        message.error(`Failed to update suite: ${message}`);
        throw error;
    }
};


// Export des fonctions génériques pour une utilisation future
export {
    fetchData,
    putData,
    patchData,
    deleteData,
    postData
};
