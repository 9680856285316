import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Modal, Space, Button, Form, Input, Typography, Select, useModalForm, useForm } from "@pankod/refine-antd";
import { useTranslate, useShow, IResourceComponentsProps,  } from "@pankod/refine-core";
import { IBooking, IBookingRoom } from "interfaces";
import { useNavigate } from "core/customNavigation";
import dayjs from "dayjs";

const { Title, Text } = Typography;

export const CreateBlockModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [bookingId, setBookingId] = useState<string | undefined>();
  const { queryResult } = useShow<IBooking>();
  const { data } = queryResult;
  const record = data?.data;

  const t = useTranslate();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    show: (id: string) => {
      setBookingId(id);
      setVisible(true);
    },
  }));

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={t("pages.booking.block")}
      visible={visible}
      onCancel={handleClose}
      footer={null}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {record?.bookingrooms.map((item) => (
          <ChangeLastCleanConsignModal bookingroomId={Number(item.id)} item={item}/>
        ))}
      </Space>
    </Modal>
  );
});

interface  ChangeLastCleanConsignModalProps extends IResourceComponentsProps {
  bookingroomId: number;
  item: any;
}

const ChangeLastCleanConsignModal: React.FC<ChangeLastCleanConsignModalProps> = (props) => {
  const { item } = props
  const t = useTranslate();
  const { formProps, form, queryResult, id, formLoading, onFinish, mutationResult } = useForm<IBooking>({
    action: "edit",
    redirect: false,     
  });
  
  const bookingrooms: [IBookingRoom] = formProps.form?.getFieldValue("bookingrooms")
  const booking = queryResult?.data?.data;
  const navigate = useNavigate();

  const submit = async () => { 
    let tmp = formProps.form?.getFieldValue(["bookingrooms", bookingrooms?.findIndex((e) => Number(e.id) == props.bookingroomId), "infos_clean"])
    tmp[Object.keys(tmp)[Object.keys(tmp).length - 1]] = {'clean_type': 'DND', 'comment': ''}
    form?.setFields([{name:["bookingrooms", bookingrooms?.findIndex((e) => Number(e.id) == props.bookingroomId), "infos_clean", Object.keys(tmp)[Object.keys(tmp).length - 1], "clean_type"], value: 'DND'}])
    form.submit()
    setTimeout(() => navigate(`/${t('menu.bookings')}/create?${t('block')}=true&room_id=${props?.item.room_id}&start_date=${props?.item.end}&end_date=${dayjs(props?.item.end).add(1, 'day').set('hour', 12).set('minute', 0).format('YYYY-MM-DDTHH:mm')}`), 2000)
  }

  if (formLoading) return (<></>)
  return (
    <>
    <Button 
            key={`block_button_${item.id}`} 
            style={{ width: '100%' }}
            onClick={(e:any) => {
              submit();
            }
          }
            
          >
            {item.room.name}
          
          </Button>
          

          <Form style={{display: 'none'}} {...formProps}>

        <Form.Item
          name="context"
          initialValue="update_infosclean"
          hidden
        ><Input/></Form.Item>
        
      {bookingrooms?.filter((e) => Number(e?.id) == props.bookingroomId)?.map((bookingroom: IBookingRoom, id: string | number) => {
      return (<div key={`ChangeCleanConsignModal_input_div_${id}`}>
          <Form.Item
              name={["bookingrooms", id, "id"]}
              initialValue={bookingroom.id}
              hidden
          >
              <Input/>
          </Form.Item>

          <br></br>  
          <Title level={4}>{`${bookingroom?.room?.name}`}</Title>
          <table>
              <tbody>   
              {Object.keys(bookingroom.infos_clean).map((valDate, index) => {
                console.log(index)
                console.log(Object.keys(bookingroom.infos_clean).length-1)
                  const infos = bookingroom.infos_clean[valDate]
                  return (
                  <tr
                      key={`ChangeCleanConsignModal_input_div__${id}_${index}`}
                  >

                      <td> {/* LABEL */}
                        <div className="ant-col ant-form-item-label">
                          <div className="ant-row ant-form-item ant-form-item-has-success">
                            <label style={{fontWeight: "bold"}} >{dayjs.utc(valDate).locale(t("short_lang")).format('dddd DD/MM/YY')}:</label>
                          </div>
                        </div>
                      </td>

                      <td>
                        <Form.Item
                          name={["bookingrooms", id, "infos_clean", valDate, "clean_type"]}
                          rules={[
                              {
                              required: true,
                              },
                          ]}
                          
                          >
                          <Select
                            options={[
                              { value: 'DND', label: t('pages.booking.infos_cleans.DND') },
                              { value: 'LCL', label: t('pages.booking.infos_cleans.LCL') },
                              { value: 'FCL', label: t('pages.booking.infos_cleans.FCL') }
                            ]}
                          />
                        </Form.Item>
                      </td>
                      <td>
                          <Form.Item
                          label={""}
                          name={["bookingrooms", id, "infos_clean", valDate, "comment"]}
                          >
                          <Input placeholder={t("comment")} ></Input>
                          </Form.Item>
                      </td>
                  </tr>
                  )
              })}
              </tbody>
          </table>
        </div>)
      })}
    </Form>
          </>
     
  )
}
