  import {
    useTranslate,
    IResourceComponentsProps,
    HttpError,
  } from "@pankod/refine-core";
  import {
    List,
    Table,
    TextField,
    useTable,
    getDefaultSortOrder,
    Form,
    Input,
    Icons,
    Switch,
    Button,
  } from "@pankod/refine-antd";
  import { IRoom, IBookingRoom, IRoomTimelineGroup, IBookingRoomTimelineItem } from "interfaces";
import { useMemo, useState } from "react";
import { useNavigate, useNavigation } from '../../core/customNavigation';
import { BookingRoomTimeline } from "./timeline";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
dayjs.extend(localizedFormat)


  export const BookingRoomList: React.FC<IResourceComponentsProps> = () => {
    const { show, create } = useNavigation()
    const t = useTranslate();  
    const [ timelineView, setTimelineView ] = useState(true)

    const switchview = () => {
        setTimelineView(!timelineView)
    }

    const isMobile = useMemo(() => {
      if (window.innerWidth <= 768)
        setTimelineView(false)
    }, [])
      if (timelineView) {
        return (
          <BookingRoomTimeline switchview={switchview}></BookingRoomTimeline>
        )
      }
    
      return (
        <BookingRoomLList switchview={switchview}></BookingRoomLList>
      )
    }  

  interface FunctProps extends IResourceComponentsProps {
    switchview(): void;
  }

  const BookingRoomLList: React.FC<FunctProps> = (props) => {
    const { show, create } = useNavigation()
    const t = useTranslate();
    const [metaData, setMetaData] = useState({ qparam: "LAST_BOOKED" });

    const { tableProps: roomTableProps, sorter: roomSorter, searchFormProps: roomSearchFormProps, tableQueryResult: roomTableQueryResult } = useTable<IRoomTimelineGroup, HttpError>({
      resource: "rooms",
      initialCurrent: 1,
      initialPageSize: 1000,
      initialSorter: [{ field: "number", order: "asc" }],
    });
    


    const { sorter, tableQueryResult, tableProps, searchFormProps } = useTable<IBookingRoomTimelineItem, HttpError>({
      resource: "bookingrooms/get_timeline",
      metaData
    });
  
      return (
        <List
          title={`${t("menu.bookings")}`}
          pageHeaderProps={{
            extra: [
              <TextField key="page_header_tl_extra_0" value={`${t("pages.booking.list_view")} : `}/>,
              <Switch key="page_header_extra_1" defaultChecked onChange={props.switchview} />,
              <Button key="page_header_extra_2"  onClick={(e:any) => setMetaData({qparam:"PAST"})} style={metaData?.qparam=="PAST"?{backgroundColor: 'blue', color: 'white'}:{}}>{t("buttons.past")}</Button>,
              <Button key="page_header_extra_3" onClick={(e:any) => setMetaData({qparam:"CURRENT"})} style={metaData?.qparam=="CURRENT"?{backgroundColor: 'blue', color: 'white'}:{}}>{t("buttons.current")}</Button>,
              <Button key="page_header_extra_4" onClick={(e:any) => setMetaData({qparam:"FUTUR"})} style={metaData?.qparam=="FUTUR"?{backgroundColor: 'blue', color: 'white'}:{}}>{t("buttons.futur")}</Button>,
              <Button key="page_header_extra_5" onClick={(e:any) => setMetaData({qparam:"LAST_BOOKED"})} style={metaData?.qparam=="LAST_BOOKED"?{backgroundColor: 'blue', color: 'white'}:{}}>{t("buttons.last_booked")}</Button>,
            ],
          }}
        >
        <Table
          {...tableProps}
          rowKey="id"
        >
          <Table.Column
            dataIndex="booking"
            key="bookingId"
            title={t("ID")}
            render={(value) => <TextField style={{"textDecorationLine": "underline"}} onClick={(e: any) => show("bookings", value.id, e)} value={value.id} />}
          />
          <Table.Column
            dataIndex="start"
            key="start"
            title={t("pages.booking.start")}
            render={(value) => <TextField value={dayjs(value).locale(t("short_lang")).format('DD/MM/YY hh:mm:ss')} />}
            defaultSortOrder={getDefaultSortOrder("start", sorter)}
          />
          <Table.Column
            dataIndex="end"
            key="end"
            title={t("pages.booking.end")}
            render={(value) => <TextField value={dayjs(value).locale(t("short_lang")).format('DD/MM/YY hh:mm:ss')} />}
            defaultSortOrder={getDefaultSortOrder("end", sorter)}
          />
          <Table.Column
            dataIndex="room_id"
            key="room_id"
            title={t("pages.booking.room")}
            render={(value) => <TextField value={`${roomTableQueryResult?.data?.data?.find(x => x.id == value)?.name}`} />}
          />
          <Table.Column
            dataIndex="booking"
            key="camefrom"
            title={t("pages.booking.camefrom")}
            render={(value) => <TextField value={value.camefrom} />}
          />
          <Table.Column
            dataIndex="booking"
            key="booking"
            title={t("pages.booking.client")}
            render={(value) => <TextField style={{"textDecorationLine": "underline"}} onClick={() => show("bookings", value.id)} value={value.client.fancy_name} />}
          />
          <Table.Column
            dataIndex="booking"
            key="booking_timestamp"
            title={t("pages.booking.booking_timestamp")}
            render={(value) => <TextField value={dayjs(value?.booking_timestamp).locale(t("short_lang")).format('DD/MM/YY hh:mm:ss')} />}
          />
          <Table.Column
            dataIndex="booking"
            key="tr_book"
            title={t("pages.booking.tr_book")}
            render={(value) => <TextField value={value.tr_book} />}
          />
          <Table.Column
            dataIndex="status"
            key="canceled"
            title={t("buttons.cancel")}
            render={(value) => {console.log(value); return(<TextField value={`${value?.["paidcancel"]? 'Oui': 'Non'}`} />)}}
          />
  
        </Table>
      </List>
      )
    }  
