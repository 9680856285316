import React, {useEffect, useState} from 'react';
import {IResourceComponentsProps, useNavigation, useShow, useTranslate} from "@pankod/refine-core";
import {
    Button,
    Card,
    Col,
    Collapse,
    ListButton,
    Modal,
    Progress,
    RefreshButton,
    Row,
    Show,
    Space,
    Spin,
    Typography
} from "@pankod/refine-antd";
import {ITedee} from "interfaces";
import {
    deleteTedeePin,
    fetchTedeePins,
    getTedeeBatteryAndStateData,
    getTedeeKeypadBatteryData,
    handlePinDetails,
    lockLock,
    unlockLock,
    updateSuite
} from "./service/tedeeService";
import moment from 'moment';

const {Title, Text} = Typography;
const {Panel} = Collapse;

export const TedeeShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const {show, list, edit} = useNavigation();
    const {queryResult} = useShow<ITedee>();
    const {data, isLoading} = queryResult;
    const record = data?.data;
    const [batteryLevel, setBatteryLevel] = useState<number>(0);
    const [state, setState] = useState<string>("Unknown");
    const [stateColor, setStateColor] = useState<string>("black");
    const [guestPins, setGuestPins] = useState<Array<{ id: number, alias: string }>>([]);
    const [staffPins, setStaffPins] = useState<Array<{ id: number, alias: string }>>([]);
    const [otherPins, setOtherPins] = useState<Array<{ id: number, alias: string }>>([]);
    const [pinDetails, setPinDetails] = useState<Record<number, any>>({});
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isLockModalVisible, setIsLockModalVisible] = useState<boolean>(false);
    const [pinToDelete, setPinToDelete] = useState<{ lockId: string, pinId: number } | null>(null);
    const [loadingPins, setLoadingPins] = useState<boolean>(true);
    const [keypadBatteryLevel, setKeypadBatteryLevel] = useState<number | string>('N/A');


    // Nouveaux états pour l'update suite
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState<boolean>(false);
    const [updateResponse, setUpdateResponse] = useState<any>(null);
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);


    useEffect(() => {
        const fetchBatteryAndStateData = async () => {
            if (record?.lock_id) {
                const batteryAndStateData = await getTedeeBatteryAndStateData();
                if (batteryAndStateData[record.lock_id] !== undefined) {
                    setBatteryLevel(batteryAndStateData[record.lock_id].batteryLevel);
                    setState(batteryAndStateData[record.lock_id].state);
                    setStateColor(batteryAndStateData[record.lock_id].color);
                }
            }
        };

        const fetchPinsData = async () => {
            if (record?.lock_id) {
                const {guestPins, staffPins, otherPins} = await fetchTedeePins(record.lock_id);
                setGuestPins(guestPins);
                setStaffPins(staffPins);
                setOtherPins(otherPins);
                setLoadingPins(false);
            }
        };

        const fetchKeypadBatteryData = async () => {
            if (record?.lock_id) {
                const keypadBatteryData = await getTedeeKeypadBatteryData();
                if (keypadBatteryData[record.lock_id]) {
                    setKeypadBatteryLevel(keypadBatteryData[record.lock_id].batteryLevel);
                }
            }
        };

        fetchBatteryAndStateData();
        fetchKeypadBatteryData();
        fetchPinsData();
    }, [record]);

    const showUnlockModal = () => {
        setIsModalVisible(true);
    };

    const showLockModal = () => {
        setIsLockModalVisible(true);
    };

    const handleOk = async () => {
        setIsModalVisible(false);
        if (record?.lock_id) {
            await unlockLock(record.lock_id);
        }
    };

    const handleLockOk = async () => {
        setIsLockModalVisible(false);
        if (record?.lock_id) {
            await lockLock(record.lock_id);
        }
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsLockModalVisible(false);
    };

    const confirmDeletePin = (lockId: string, pinId: number) => {
        setPinToDelete({lockId, pinId});
        setIsModalVisible(true);
    };

    const handleDeletePin = async () => {
        if (pinToDelete) {
            try {
                await deleteTedeePin(pinToDelete.lockId, pinToDelete.pinId);
                setGuestPins((prev) => prev.filter(pin => pin.id !== pinToDelete.pinId));
                setStaffPins((prev) => prev.filter(pin => pin.id !== pinToDelete.pinId));
                setOtherPins((prev) => prev.filter(pin => pin.id !== pinToDelete.pinId));
                setPinDetails((prev) => {
                    const updatedDetails = {...prev};
                    delete updatedDetails[pinToDelete.pinId];
                    return updatedDetails;
                });
            } catch (error) {
                console.error("Failed to delete PIN:", error);
            }
            setIsModalVisible(false);
        }
    };

    const editPin = (lockId: string, pinId: number) => {
        edit("tedeePin", pinId);
    };

    // Fonction pour gérer le clic sur le bouton "Update Suite"
    const handleUpdateSuite = async () => {
        if (record?.room) {
            setLoadingUpdate(true);
            setIsUpdateModalVisible(true);
            try {
                const data = await updateSuite(record.room); // Appeler la fonction depuis le service
                setUpdateResponse(data);
            } catch (error) {
                console.error('Failed to update suite:', error);
                setUpdateResponse({error: 'Failed to update suite'});
            } finally {
                setLoadingUpdate(false);
            }
        } else {
            console.error('room is not available in record');
            setUpdateResponse({error: 'room is not available'});
            setIsUpdateModalVisible(true);
        }
    };


    if (isLoading) {
        return <div>{t("tedee.loading")}</div>
    }

    const removePrefix = (alias: string) => {
        return alias.replace(/^(Guest|Staff|Other) :\s*/, '');
    };

    const formatDateTime = (dateTime: string | null) => {
        if (!dateTime) {
            return <Text style={{color: 'red'}}> Illimité</Text>; // Texte en rouge
        }
        return moment(dateTime).add(2, 'hours').format(' YYYY-MM-DD HH:mm');
    };

    const formatTimeRange = (startTime: string | null, endTime: string | null) => {
        if (!startTime && !endTime) {
            return <Text style={{color: 'red'}}> Sans plage horaires</Text>;
        }
        const formattedStartTime = startTime ? moment(startTime).add(2, 'hours').format('HH:mm') : '';
        const formattedEndTime = endTime ? moment(endTime).add(2, 'hours').format('HH:mm') : '';
        return <Text>{formattedStartTime && formattedEndTime ? `${formattedStartTime} - ${formattedEndTime}` : ''}</Text>;
    };


    const handlePinDetailsWrapper = async (lockId: string, pinId: number) => {
        await handlePinDetails(lockId, pinId, setPinDetails);
    };

    const renderPins = (pins: Array<{ id: number, alias: string }>, type: string) => {
        return pins.map(pin => {
            const alias = removePrefix(pin.alias);
            return (
                <Collapse
                    key={pin.id}
                    onChange={() => handlePinDetailsWrapper(record?.lock_id!, pin.id)}
                    style={{marginLeft: '20px'}}
                >
                    <Panel header={
                        <Row justify="space-between" align="middle" style={{width: '100%'}}>
                            <Col>
                                {`${alias} (${type})`}
                            </Col>
                            <Col>
                                <Space>
                                    <Button type="default" danger onClick={(e) => {
                                        e.stopPropagation();
                                        confirmDeletePin(record?.lock_id!, pin.id);
                                    }}>{t("tedee.delete")}</Button>
                                </Space>
                            </Col>
                        </Row>
                    } key={pin.id}>
                        {pinDetails[pin.id] ? (
                            <>
                                <Text strong>{t("tedee.pin.id")}</Text> <Text>{pin.id}</Text><br/>
                                <Text strong>{t("tedee.pin.pin")}</Text> {type === 'Staff' ? (
                                <Text style={{color: 'red'}}>{t("tedee.pin.private")}</Text>
                            ) : (
                                <Text>{pinDetails[pin.id].pin}</Text>
                            )}<br/>
                                <Text strong>{t("tedee.pin.startdate")}</Text>
                                <Text>{formatDateTime(pinDetails[pin.id].startDate)}</Text><br/>
                                <Text strong>{t("tedee.pin.enddate")}</Text>
                                <Text>{formatDateTime(pinDetails[pin.id].endDate)}</Text><br/>
                                <Text strong>{t("tedee.pin.time_range")}</Text>
                                {formatTimeRange(pinDetails[pin.id].dayStartTime, pinDetails[pin.id].dayEndTime)}<br/>
                            </>
                        ) : (
                            <Text>{t("tedee.loading")}</Text>
                        )}
                    </Panel>
                </Collapse>
            );
        });
    };

    const getBatteryColor = (batteryLevel: number) => {
        if (batteryLevel > 50) return 'green';
        if (batteryLevel > 20) return 'orange';
        return 'red';
    };

    return (
        <Show pageHeaderProps={{
            title: "",
            extra: [
                <ListButton key={'div_extra_03'}></ListButton>,
                <RefreshButton key="refresh" onClick={handleRefresh}>{t("tedee.refresh")}</RefreshButton>,
            ]
        }}>
            <div>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Card title={t("tedee.name")} style={{
                            backgroundColor: "#EFEFEF",
                            width: "100%",
                            maxWidth: "500px",
                            padding: '10px',
                            margin: "0px auto 10px auto",
                            textAlign: "center"
                        }}>
                            <Title level={3}>{record?.name}</Title>
                            <span style={{color: stateColor}}>{state}</span>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card
                            title={t("tedee.battery")}
                            style={{
                                backgroundColor: "#EFEFEF",
                                width: "100%",
                                maxWidth: "500px",
                                padding: '10px',
                                margin: "0px auto 10px auto",
                                textAlign: "center"
                            }}
                        >
                            {/* Batterie du Lock */}
                            <div style={{marginBottom: '15px'}}>
                                <Text strong>{t("tedee.lock_battery")}</Text>
                                <Progress
                                    percent={batteryLevel}
                                    strokeColor={getBatteryColor(batteryLevel)}
                                    showInfo={false}
                                />
                                <Text>{batteryLevel}%</Text>
                            </div>

                            {/* Batterie du Keypad */}
                            <div>
                                <Text strong>{t("tedee.keypad_battery")}</Text>
                                {keypadBatteryLevel !== 'N/A' ? (
                                    <>
                                        <Progress
                                            percent={Number(keypadBatteryLevel)}
                                            strokeColor={getBatteryColor(Number(keypadBatteryLevel))}
                                            showInfo={false}
                                        />
                                        <Text>{keypadBatteryLevel}%</Text>
                                    </>
                                ) : (
                                    <Text>{t("tedee.not_available")}</Text>
                                )}
                            </div>
                        </Card>
                    </Col>


                    <Col span={12}>
                        <Card title={t("tedee.remote_unlock")} style={{
                            backgroundColor: "#EFEFEF",
                            width: "100%",
                            maxWidth: "500px",
                            padding: '10px',
                            margin: "0px auto 10px auto",
                            textAlign: "center"
                        }}>
                            <Space>
                                <Button type="primary" onClick={showUnlockModal}>{t("tedee.unlock")}</Button>
                                <Button type="primary" onClick={showLockModal}>{t("tedee.lock")}</Button>
                                <Button type="primary" onClick={handleUpdateSuite}>{t("tedee.update_suite")}</Button>
                            </Space>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title={t("tedee.guest_codes")} style={{
                            backgroundColor: "#EFEFEF",
                            width: "100%",
                            maxWidth: "500px",
                            padding: '10px',
                            margin: "0px auto 10px auto",
                            textAlign: "center"
                        }}>
                            {loadingPins ? <Spin/> : renderPins(guestPins, 'Guest')}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title={t("tedee.staff_codes")} style={{
                            backgroundColor: "#EFEFEF",
                            width: "100%",
                            maxWidth: "500px",
                            padding: '10px',
                            margin: "0px auto 10px auto",
                            textAlign: "center"
                        }}>
                            {loadingPins ? <Spin/> : renderPins(staffPins, 'Staff')}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title={t("tedee.other_codes")} style={{
                            backgroundColor: "#EFEFEF",
                            width: "100%",
                            maxWidth: "500px",
                            padding: '10px',
                            margin: "0px auto 10px auto",
                            textAlign: "center"
                        }}>
                            {loadingPins ? <Spin/> : renderPins(otherPins, 'Other')}
                        </Card>
                    </Col>
                </Row>
                <Modal
                    title={pinToDelete ? t("tedee.modal.confirmdelete") : t("tedee.modal.confirmunlock")}
                    visible={isModalVisible}
                    onOk={pinToDelete ? handleDeletePin : handleOk}
                    onCancel={handleCancel}
                    okText={pinToDelete ? t("tedee.modal.delete") : t("tedee.modal.unlock")}
                    cancelText={t("tedee.cancel")}
                >
                    <p>{pinToDelete ? t("tedee.modal.suredelete") : t("tedee.modal.sureunlock")}</p>
                </Modal>
                <Modal
                    title={t("tedee.modal.confirmlock")}
                    visible={isLockModalVisible}
                    onOk={handleLockOk}
                    onCancel={handleCancel}
                    okText={t("tedee.lock")}
                    cancelText={t("tedee.cancel")}
                >
                    <p>{t("tedee.modal.surelock")}</p>
                </Modal>
                <Modal
                    title={t("tedee.update_suite_response")}
                    visible={isUpdateModalVisible}
                    onOk={() => setIsUpdateModalVisible(false)}
                    onCancel={() => setIsUpdateModalVisible(false)}
                    footer={[
                        <Button key="ok" type="primary" onClick={() => setIsUpdateModalVisible(false)}>
                            {t("tedee.ok")}
                        </Button>,
                    ]}
                >
                    {loadingUpdate ? (
                        <Spin/>
                    ) : updateResponse ? (
                        <div>
                            {updateResponse.message && <p>{updateResponse.message}</p>}
                            {updateResponse.output && (
                                <pre style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>
                                    {updateResponse.output.join('\n')}
                                </pre>
                            )}
                            {updateResponse.error && <p style={{color: 'red'}}>{updateResponse.error}</p>}
                        </div>
                    ) : null}
                </Modal>
            </div>
        </Show>
    );
};
