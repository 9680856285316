import { useModalForm, Modal, DatePicker, Form, Input, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { InputPlusMinus } from "components/inputs/inputplusminus";
import { IBooking, IBookingRoom, IBookingRoomPrice } from "interfaces";
import { forwardRef, useImperativeHandle, useState } from "react";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
dayjs.extend(localizedFormat)

const { Title, Text } = Typography;


export const ChangeDatesModal = forwardRef((props, ref) => {
    const t = useTranslate();
    const { modalProps, formProps, form, queryResult, id, show, close, formLoading, } = useModalForm<IBooking>({
      action: "edit",
      redirect: false,
  
      
    });
    const [bookDates, setBookDates] = useState<any>({start: '', end: '', days: []})
    const [originBookDates, setOriginBookDates] = useState<any>({start: '', end: '', days: []})
      
    const bookingrooms: [IBookingRoom] = formProps.form?.getFieldValue("bookingrooms")
    const booking = queryResult?.data?.data;
    modalProps.title = <Title level={4} >{`${t("buttons.edit")} ${t("pages.booking.title")}`}</Title>
  
    useImperativeHandle(ref, () => ({
      show(id: string) {
        show(id);
        const start = booking?.bookingrooms?.[0].start;
        const end = booking?.bookingrooms?.[0].end;

        rangeChange([start, end])
        // const daysRange = getDaysArray(start, end);
        setOriginBookDates({start: start, end: end, days: getDaysArray(start, end)})
      }
    }));
  
  
    const formSubmit = () => {
      form.submit() 
    }  
  
    const rangeChange = (a: any) => {
      const newDaysRange = getDaysArray(a[0], a[1]);
      setBookDates({start: a[0], end: a[1], days: newDaysRange})

      bookingrooms?.map((_br: IBookingRoom, iBr: number) => {
        newDaysRange?.map((dt: Date, index: number) => {
          form.setFields([{
            name: ["bookingrooms", iBr, "price", "dates", index, "date"],
            value: dayjs.utc(new Date(dt)),
          }]);
          if (form.getFieldValue(["bookingrooms", iBr, "price", "dates", index, "hostingPrice"]) == undefined) {
            form.setFields([{
              name: ["bookingrooms", iBr, "price", "dates", index, "hostingPrice"],
              value: 0,
            }]);  
          }
        })  
      })
    }
    const getDaysArray = (start:any, end:any) => {
      for(var arr=[],dt=new Date(start); dt<new Date(end); dt.setDate(dt.getDate()+1)){
          arr.push(new Date(dt));
      }
      return arr;
    }
  
    return (
      <Form  {...formProps}>
      <Modal title={t("pages.booking.relocate")} {...modalProps} okButtonProps={{onClick: () => formSubmit()}}   >
  
      <DatePicker.RangePicker allowClear={false} format={t("short_date")} onChange={rangeChange} monthCellRender={(curr) => dayjs(curr).locale("fr").format("MMM")} defaultValue={[dayjs.utc(bookingrooms?.[0].start), dayjs.utc(bookingrooms?.[0].end)]}/>
  
          <Form.Item
            name="context"
            initialValue="update_dateprice"
            hidden
          ><Input/></Form.Item>
          
        {bookingrooms?.map((bookingroom: IBookingRoom, id: string | number) => {
        return (<div key={`ChangeDatesModal_input_div_${id}`}>
            <br/>
            <Title level={4}>{`${bookingroom?.room?.name}`}</Title>
            <Form.Item
                name={["bookingrooms", id, "id"]}
                initialValue={bookingroom.id}
                hidden
            >
                <Input/>
            </Form.Item>

            <br></br>
  
            <table>
                <tbody>
                {bookDates.days.map((day: Date, index: number) => {
                  const is_newDate = !originBookDates?.days?.find((originDay: Date) => originDay.toDateString()==day.toDateString())
                    return (
                    <tr
                        key={`ChangeDatesModal_input_div__${id}_${index}`}
                    >
                        <td >
                            <Form.Item
                            label={dayjs.utc(day).locale(t("short_lang")).format('dddd DD/MM/YY')}
                            name={["bookingrooms", id, "price", "dates", index, "date"]}
                            getValueProps={(value) => ({
                                value: value ? dayjs.utc(value) : "",
                            })}
                            rules={[
                                {
                                required: true,
                                },
                            ]}
                            style={{backgroundColor: is_newDate? 'rgba(255,165,0,0.25)': ''}}
                            >
                            <Input style={{display: "none"}}></Input>
                            </Form.Item>
                        </td>
                        <td>
                            <Form.Item
                            label={""}
                            name={["bookingrooms", id, "price", "dates", index, "hostingPrice"]}
                            rules={[
                                {
                                required: true,
                                },
                            ]}
                            >
                            <InputPlusMinus></InputPlusMinus> 
                            </Form.Item>
                        </td>
                    </tr>
                    )
                })}
                </tbody>
            </table>
          </div>)
        })}
      </Modal>
      </Form>
    )
});